import {AppManifestBuilder} from '@wix/yoshi-flow-editor'
import {EVENTS_DETAILS_PAGE_ID} from '@wix/wix-events-commons-statics'
import {WidgetPluginInterfaces} from '@wix/widget-plugins-ooi/interfaces'
import {TFunction} from '../services/translations'

export const detailsPageSlotsBuilder =
  ({t}: {t: TFunction}) =>
  async (appManifestBuilder: AppManifestBuilder) => {
    return appManifestBuilder.configureWidget(EVENTS_DETAILS_PAGE_ID, widgetBuilder =>
      widgetBuilder.slots().set({
        header: {
          displayName: 'Header',
          interfaces: [WidgetPluginInterfaces.DEFAULT],
        },
        details: {
          displayName: 'Details',
          interfaces: [WidgetPluginInterfaces.DEFAULT],
        },
        map: {
          displayName: 'Map',
          interfaces: [WidgetPluginInterfaces.DEFAULT, WidgetPluginInterfaces.REVIEWS],
        },
      }),
    )
  }
